import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Email, Facebook, Pinterest, Twitter } from "react-sharingbuttons";
import PropTypes from 'prop-types';
import DishList from "./DishList"
import DishForm from "./DishForm";
import 'react-sharingbuttons/dist/main.css';
import './Event.css';

class Event extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: this.props.events,
      dishes: this.props.dishes, 
      editDish: undefined,
      success: undefined,
      error: undefined
    };
  }

  componentDidUpdate(prevProps){
    if (this.props.events !== prevProps.events) {
      this.setState({
        events: this.props.events,
      })
    }
  }

  cancelDishUpdate = (event) => {
    this.setState({ editDish: undefined })
  }

  scrollToSection = (id) => {
    let element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  addDish = (newDish) => {
    this.props.addDishCallback(newDish);
  }

  editDish = (editedDish) => {
    this.scrollToSection("dish-section");
    let allDishes = this.props.dishes;

    let originalDish = allDishes.find((dish) => dish.id === editedDish.id)
    this.setState({ editDish: originalDish })
  }

  editDishCallback = (editedDish) => {
    this.setState({ editDish: undefined })
    this.props.editDishCallback(editedDish)
  }

  deleteDish = (deletedDish) => {
    this.props.deleteDishCallback(deletedDish);
  }

  formatTime = (time) => {
    let timePieces = time.split(":");
    let period;
    let hour = parseInt(timePieces[0]);

    if (hour < 12) {
      period = "AM"
    } else if (hour === 12) {
      period = "PM" 
    } else {
      hour -= 12;
      period = "PM"
    }
    return `${hour}:${timePieces[1]} ${period}`
  }

  sharingButtons = (externalId, title, photo) => {
    const url = `https://potluckpal.com/events/${externalId}`
    const text = `You are invited to ${title}!`

    return (
      <div id="event-share">
        <div>
          <Email subject={ text } url={ url }/>
          <Facebook url={ url } />
        </div>
        <div>
          <Pinterest shareText={ text } url={ url } mediaSrc={ photo }/>
          <Twitter shareText={ text } url={ url } />
        </div>
      </div>
    )
  }

  render(){
    let { eventId } = this.props.match.params;

    let events = this.props.events;

    let foundEvent = events.find((event) => event.external_id === eventId);

    if (foundEvent) {
      let date_pieces = foundEvent.date.split("-");
      let js_date = new Date(date_pieces[0], (date_pieces[1]-1), date_pieces[2]);

      let js_start_time = this.formatTime(foundEvent.start_time);
      let js_end_time = this.formatTime(foundEvent.end_time);

      let user = this.props.users.find((user) => user.id === foundEvent.host);

      return <div className="event container-fluid">
        <h1>{foundEvent.title}</h1>
        <div className="event-header">
          <img src={ foundEvent.photo_url ? foundEvent.photo_url : this.props.placeholder } alt={ foundEvent.title }/>
          <div className="event-details">
            <p><span className="label">Date: </span>{ js_date.toDateString()}</p>
            <p><span className="label">Start Time: </span>{ js_start_time }</p>
            <p><span className="label">End Time: </span>{ js_end_time }</p>
            <p><span className="label">Location: </span>{ foundEvent.location }</p>
            <p><span className="label">Host: </span>{ user.first_name } { user.last_name }</p>
            <p><span className="label">Message: </span>{ foundEvent.message }</p>
          </div>
          <div>
            { this.sharingButtons(foundEvent.external_id, foundEvent.title, foundEvent.photo_url) }
          </div>
        </div>
        <div className="dish-list">
          <h3>Dishes</h3>
          { this.state.success ? <div className="alert alert-success" role="alert">{ this.state.success }</div> : null }
          { this.state.error ? <div className="alert alert-warning" role="alert">{ this.state.error }</div> : null }
          <DishList placeholder={ this.props.placeholder } editDishCallback={ this.editDish } dishes={ foundEvent.dishes } deleteDishCallback={ this.deleteDish } />
        </div>
        <div className="add-dish">
          <DishForm className="new-dish_form" selectedRecipe={ this.props.selectedRecipe } eventId={ foundEvent.id } addDishCallback={ this.addDish } 
            editDish={ this.state.editDish } editDishCallback={ this.editDishCallback } cancelClickedCallback={ this.cancelDishUpdate } />
        </div>
        </div>
    }
    return <div>
    </div>
  }
}

Event.propTypes = {
  users: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  dishes: PropTypes.array.isRequired,
  addDishCallback: PropTypes.func.isRequired,
  editDishCallback: PropTypes.func.isRequired,
  deleteDishCallback: PropTypes.func.isRequired
}

export default withCookies(Event);
