import React from "react";
import { withCookies } from "react-cookie";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import './RecipeList.css';

function RecipeList(props) {
  let allRecipes = props.recipes;

  function compare(a, b) {
    if (a.title > b.title) return 1;
    if (b.title > a.title) return -1;
    return 0;
  }

  allRecipes.sort(compare);

  const addSelectedCallback = recipe => event => {
    let dishDetails = { name: recipe.title, link: `/recipes/${recipe.id}`, photo_url: recipe.photo_url }
    props.addSelectedCallback(dishDetails)
  }

  return <div className="recipes">
    <div className="recipe-list">
      { allRecipes.map( recipe => { return <div className="card" key={recipe.id}>
          <Link id="card-link" to={"/recipes/" + recipe.id}>
            <img src={recipe.photo_url ? recipe.photo_url : props.placeholder} className="card-img-top" alt={recipe.title}/>
          </Link>
          <div className="card-body">
            <p className="card-text"><Link id="card-link" to={"/recipes/" + recipe.id}>{recipe.title}</Link></p>
            { props.deleteRecipeCallback ? 
            <div className="card-buttons">
              <button type="submit" name="submit" className="btn" id="btn-mint-left" onClick={ props.editRecipeCallback(recipe) }>Edit</button>
              <button type="submit" name="submit" className="btn" id="btn-gray" onClick={ props.deleteRecipeCallback(recipe) }>Delete</button>
            </div>
            : <button type="submit" name="submit" className="btn" id="select-external-recipe" 
            onClick={ addSelectedCallback(recipe) }>Select Recipe</button>}
          </div>
        </div>
        })
      }
    </div>
  </div>
}

RecipeList.propTypes = {
  recipes: PropTypes.array.isRequired,
  users: PropTypes.array,
  deleteRecipeCallback: PropTypes.func,
  editRecipeCallback: PropTypes.func
}

export default withCookies(RecipeList);
