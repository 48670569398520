import React from "react";
import { withCookies } from "react-cookie";
import './ExternalRecipeList.css';

function ExternalRecipeList(props) {
  let allRecipes = props.recipes;

  const addSelectedCallback = recipe => event => {
    let dishDetails = { name: recipe.label, link: recipe.url, photo_url: recipe.image }
    props.addSelectedCallback(dishDetails)
  }

  if (allRecipes) {
    return <div className="recipes">
        <div className="recipe-list">
          { allRecipes.map( (item, i) => { return <div className="card" key={i}>
              <a href={item.recipe.url} id="card-link" target="_blank" rel="noopener noreferrer">
                <img src={item.recipe.image} className="card-img-top" alt={item.recipe.label}/>
              </a>
              <div className="card-body">
              <p className="card-text"><a href={item.recipe.url} id="card-link" 
                target="_blank" rel="noopener noreferrer">{item.recipe.label}</a></p>
              <p className="card-text">{item.recipe.source}</p>
              <button type="submit" name="submit" className="btn" id="select-external-recipe" 
                onClick={ addSelectedCallback(item.recipe) }>Select Recipe</button>
              </div>
            </div>
            }
          )}
        </div>
      </div>
  } else {
    return <div></div>
  }
}

export default withCookies(ExternalRecipeList);
