import React from "react";
import { withCookies } from "react-cookie";
import RecipeList from "./RecipeList";
import ExternalRecipeList from "./ExternalRecipeList";
import './Search.css';

function Search(props) {
  let allRecipes = props.recipes;
  let allUsers = props.users;

  let search = props.search_terms.toLowerCase();
  let regex = `.*${search}.*`;

  let internalRecipes = allRecipes.filter((recipe) => (recipe.title.toLowerCase().match(regex)));

  let externalRecipes = props.external_results;

  const addSelectedCallback = (dishDetails) => {
    props.addSelectedCallback(dishDetails);
  }

  return <div className="search container-fluid">
    <h1>Recipe Search</h1>
    <div className="search">
      <h3>Internal Results</h3>
      <div className="internal-results">
        <RecipeList addSelectedCallback={ addSelectedCallback } placeholder={ props.placeholder } recipes={ internalRecipes } users={ allUsers } />
      </div>
      
      <h3>External Results</h3>
      <div id="external-results">
        <ExternalRecipeList addSelectedCallback={ addSelectedCallback } recipes={ externalRecipes} />
      </div>
    </div>
  </div>
}

export default withCookies(Search);
