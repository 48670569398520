import React, { Component } from 'react';
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase/app";
import "firebase/storage";
import './EventForm.css';

class EventForm extends Component {
  constructor(props) {
    super(props);

    let userId = -1

    if (this.props.currentUser) {
      userId = this.props.currentUser.id
    } 

    this.state = {
      currentEvent: {
        title: "",
        photo_url: "",
        date: "",
        start_time: "",
        end_time: "",
        location: "",
        host: userId,
        message: "",
      },
      editMode: !!props.editEvent,
      uploadName: "No file chosen",
      success: undefined,
      error: undefined,
      key: 0
    };
  }

  componentDidMount(){
    if (this.userId === -1) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.editEvent && (this.props.editEvent !== prevProps.editEvent)) {
      let eventCopy = JSON.parse(JSON.stringify(this.props.editEvent));

      this.setState({
        currentEvent: eventCopy, 
        editMode: !!this.props.editEvent,
        uploadName: "No file chosen",
        success: undefined,
        error: undefined
      })
    }
  }

  setPhoto(filename, url) {
    let updatedEvent = this.state.currentEvent;
    updatedEvent.photo_url = url;

    this.setState({ 
      currentEvent: updatedEvent,
      uploadName: filename })    
  }

  handleUploadSuccess = filename => {
    firebase
      .storage()
      .ref("events")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setPhoto(filename, url))
  };

  handleUploadError = error => {
    console.log(error);
  }

  cancelClicked = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;

    this.setState({ 
      currentEvent: { 
        title: "", photo_url: "", date: "", start_time: "", end_time: "", location: "", host: this.props.currentUser.id, message: "" 
      },
      editMode: false,
      uploadName: "No file chosen",
      success: undefined,
      error: undefined,
      key: newKey
    })

    this.props.cancelClickedCallback();
  }
  
  inputChanged = event => {
    let updatedEvent = this.state.currentEvent;
    updatedEvent[event.target.name] = event.target.value;
    this.setState({ currentEvent: updatedEvent });
  }

  createEvent = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;

    this.setState({ 
      key: newKey
    })

    fetch(`${process.env.REACT_APP_API_URL}/events/`, {
      method: "POST", 
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${this.props.token}`},
      body: JSON.stringify(this.state.currentEvent)})
      .then(response => response.json())
      .then(response => {
        if (response.id) {
          let eventTitle = response.title;
          this.props.addEventCallback(response);
          this.setState({ 
            currentEvent: { 
              title: "", photo_url: "", date: "", start_time: "", end_time: "", location: "", host: this.userId, message: "" 
            },
            uploadName: "No file chosen",
            success: `Event ${eventTitle} was successfully created.`,
            error: undefined 
          })
        } else {
          this.setState({ error: response.detail })
        }
      })
      .catch(error => console.log(error)
      )
    }

  editEvent = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;

    this.setState({ 
      key: newKey
    })
  
    fetch(`${process.env.REACT_APP_API_URL}/events/${this.state.currentEvent.external_id}/`, {
      method: "PATCH", 
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${this.props.token}`},
      body: JSON.stringify(this.state.currentEvent)})
      .then(response => response.json())
      .then(response => {
        if (response.id) {
          let eventTitle = response.title;
          this.props.editEventCallback(response);
          this.setState({
            currentEvent: {
              title: "", photo_url: "", date: "", start_time: "", end_time: "", location: "", host: this.props.currentUser.id, message: ""
            },
            editMode: false,
            uploadName: "No file chosen",
            success: `Event ${eventTitle} was successfully updated.`,
            error: undefined
          })
        } else {
          this.setState({ error: response.detail })
        }
      })
      .catch(error => console.log(error)
      )
    }

  render() {
    let selectedEvent = this.state.currentEvent;

    let editMode = this.state.editMode;

    return (
      <div id="event-section" className="event-form"> 
        <h3>{ editMode ? "Edit" : "Add" } Event</h3>
        { this.state.success ? <div className="alert alert-success" role="alert">{ this.state.success }</div> : null }
        { this.state.error ? <div className="alert alert-warning" role="alert">{ this.state.error }</div> : null }
        <form className="needs-validation" onSubmit={ editMode ? this.editEvent : this.createEvent } autoComplete="on">
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="title">Title</label>
              <input className="form-control" type="text" name="title" placeholder="Title" value={ selectedEvent.title } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="date">Date</label>
              <input className="form-control" type="date" name="date" value={ selectedEvent.date } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="start_time">Start Time</label>
              <input className="form-control" type="time" name="start_time" value={ selectedEvent.start_time } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="end_time">End Time</label>
              <input className="form-control" type="time" name="end_time" value={ selectedEvent.end_time } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-3">
              <div><label>Photo</label></div>
              <label id="recipe-photo-upload" className="btn">
                Choose
              <FileUploader key={ this.state.key } hidden accept="image/*" name="photo" storageRef={ firebase.storage().ref("events") } 
              onUploadSuccess={ this.handleUploadSuccess } onUploadError={ this.handleUploadError } />
              </label>
              <span id="filename">{ this.state.uploadName } </span>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-5">
              <label htmlFor="location">Location</label>
              <input className="form-control" type="text" name="location" placeholder="Location" value={ selectedEvent.location } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="message">Message</label>
              <input className="form-control" type="text" name="message" placeholder="Message" value={ selectedEvent.message } 
                onChange={ this.inputChanged } required />
            </div>
          </div>
          <div id="event-form-buttons" className="form-row">
            <div className="form-group">  
              <button className="btn" id="btn-mint" type="submit" name="submit">{ editMode ? "Update" : "Create" } Event</button>
            </div>
            <div className="form-group">
              <button className="btn" id="btn-gray-right" type="submit" name="submit" onClick={ this.cancelClicked }>Cancel</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default EventForm;
