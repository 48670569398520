import React from "react";
import { withCookies } from "react-cookie";
import { Link } from "react-router-dom";
import './Dish.css';

const Dish = (dish) => {
  let internal = dish.link.startsWith("/recipes");

  let link;

  if (dish.link && (internal || dish.link.startsWith("http"))) {
    link = dish.link
  } else if (dish.link) {
    link = "//" + dish.link
  }

  return (
    <React.Fragment>
      <td>{ dish.provider }</td>
      <td>{ internal ? <Link to={link}>{ dish.name }</Link>
          : <a href={ link } target="_blank" rel="noopener noreferrer">{ dish.name }</a>}</td>
      <td>{ internal ? <Link to={link}><img src={dish.photo} alt={dish.name}/></Link>
          : <a href={ link } target="_blank" rel="noopener noreferrer"><img src={ dish.photo } alt={ dish.name }/></a>}</td>
      <td>{ dish.notes }</td>
      <td><button className="btn" id="btn-mint" onClick={ dish.editDishCallback(dish) } >Edit</button></td>
      <td><button className="btn" id="btn-gray" onClick={ dish.deleteDishCallback(dish) }>Delete</button></td>
    </React.Fragment>    
  );
}

export default withCookies(Dish);
