import React, { Component } from 'react';
import { withCookies } from "react-cookie";
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase/app";
import "firebase/storage";
import './DishForm.css';

class DishForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDish: {
        event: this.props.eventId,
        provider: "",
        photo_url: "",
        name: "",
        link: "",
        notes: "",
      },
      editMode: !!props.editDish,
      uploadName: "No file chosen",
      currentUser: this.props.cookies.get("pp-user", { path: "/" }),
      success: undefined,
      error: undefined,
      key: 0
    };
  }

  componentDidMount() {
    let selectedDish = this.props.selectedRecipe;

    if (this.props.selectedRecipe) {
      selectedDish.event = this.props.eventId;
      selectedDish.provider = this.state.currentUser ? 
        `${ this.state.currentUser.first_name } ${ this.state.currentUser.last_name }` : "";
      selectedDish.notes = "";

      this.setState({ 
        currentDish: selectedDish,
        uploadName: `${ selectedDish.name }.jpg`
      })
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.editDish && (this.props.editDish !== prevProps.editDish)) {
      let dishCopy = JSON.parse(JSON.stringify(this.props.editDish));

      this.setState({
        currentDish: dishCopy,
        editMode: !!this.props.editDish,
        uploadName: `${ dishCopy.name }.jpg`,
        success: undefined,
        error: undefined
      })
    } else if (this.props.selectedRecipe && (this.props.selectedRecipe !== prevProps.selectedRecipe)) {
      let selectedDish = this.props.selectedRecipe;
      selectedDish.event = this.props.eventId;
      selectedDish.provider = this.state.currentUser ? 
        `${ this.state.currentUser.first_name } ${ this.state.currentUser.last_name }` : "";
      selectedDish.notes = "";

      this.setState({ 
        currentDish: selectedDish,
        uploadName: `${ selectedDish.name }.jpg`
      });
    } else if (!this.props.selectedRecipe && (this.props.selectedRecipe !== prevProps.selectedRecipe)) {
      this.setState({
        currentDish: {
          event: this.props.eventId,
          provider: "",
          photo_url: "",
          name: "",
          link: "",
          notes: "",
        },
        uploadName: "No file chosen"
      })
    }
  }

  setPhoto(filename, url) {
    let updatedDish = this.state.currentDish;
    updatedDish.photo_url = url;

    this.setState({ 
      currentDish: updatedDish,
      uploadName: filename })    
  }

  handleUploadSuccess = filename => {
    firebase
      .storage()
      .ref("dishes")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setPhoto(filename, url))
  };

  handleUploadError = error => {
    console.log(error);
  }

  cancelClicked = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;

    this.setState({ 
      currentDish: { 
        event: this.props.eventId, provider: "", photo_url: "", name: "", link: "", notes: "" 
      },
      editMode: false,
      uploadName: "No file chosen",
      success: undefined,
      error: undefined,
      key: newKey,
    })

    this.props.cancelClickedCallback();
  }

  inputChanged = event => {
    let updatedDish = this.state.currentDish;
    updatedDish[event.target.name] = event.target.value;
    this.setState({ currentDish: updatedDish });
  }

  createDish = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;

    this.setState({ 
      key: newKey
    })

    fetch(`${process.env.REACT_APP_API_URL}/dishes/`, {
      method: "POST", 
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${ this.props.token }`},
      body: JSON.stringify(this.state.currentDish)})
      .then(response => response.json())
      .then(response => {
        if (response.id) {
          let dishName = response.name;
          this.props.addDishCallback(response);
          this.setState({ 
            currentDish: { 
              event: this.props.eventId, provider: "", photo_url:"", name: "", link: "", notes: "" 
            },
            uploadName: "No file chosen",
            success: `Dish ${ dishName } was successfully added.`,
            error: undefined
          })
        } else {
          this.setState({ error: response.detail })
        }  
      })
      .catch(error => console.log(error)
      )
    }

  editDish = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;

    this.setState({ 
      key: newKey
    })
  
    fetch(`${ process.env.REACT_APP_API_URL }/dishes/${ this.state.currentDish.id }/`, {
      method: "PATCH", 
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${ this.props.token }`},
      body: JSON.stringify(this.state.currentDish)})
      .then(response => response.json())
      .then(response => {
        if (response.id) {
          let dishName = response.name;
          this.props.editDishCallback(response);
          this.setState({
            currentDish: {
              event: this.props.eventId, provider: "", photo_url: "", name: "", link: "", notes: ""
            },
            editMode: false,
            uploadName: "No file chosen",
            success: `Dish ${ dishName } was successfully updated.`,
            error: undefined
          })
        } else {
          this.setState({ error: response.detail })
        }
      })
      .catch(error => console.log(error)
      )
    }

  render() {
    let selectedDish = this.state.currentDish;

    let editMode = this.state.editMode;

    return (
      <div id="dish-section" className="dish-form"> 
        <h3>{ editMode ? "Edit" : "Add" } Dish</h3>
        { this.state.success ? <div className="alert alert-success" role="alert">{ this.state.success }</div> : null }
        { this.state.error ? <div className="alert alert-warning" role="alert">{ this.state.error }</div> : null }
        <form className="dish-form" onSubmit={ editMode ? this.editDish : this.createDish } autoComplete="on">
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="provider">Guest Name</label>
              <input className="form-control" type="text" name="provider" placeholder="Guest Name" value={ selectedDish.provider } onChange={ this.inputChanged } required/>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="name">Dish</label>
              <input className="form-control" type="text" name="name" placeholder="Dish" value={ selectedDish.name } onChange={ this.inputChanged } required/>
            </div>
            <div className="form-group col-md-3">
              <div><label>Photo</label></div>
              <label id="dish-photo-upload" className="btn">
                Choose
                <FileUploader key={ this.state.key } hidden accept="image/*" name="photo" storageRef={ firebase.storage().ref("dishes") } 
                  onUploadSuccess={ this.handleUploadSuccess } onUploadError={ this.handleUploadError } /> 
              </label>
              <span id="filename">{ this.state.uploadName }</span>
            </div>
          
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="link">Recipe Link</label>
              <input className="form-control" type="text" name="link" placeholder="Recipe Link (optional)" 
                value={ selectedDish.link } onChange={ this.inputChanged } />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="notes">Notes, Food Allergens</label>
              <input className="form-control" type="text" name="notes" placeholder="Notes, Food Allergens (optional)" 
                value={ selectedDish.notes } onChange={ this.inputChanged } />
            </div>
          </div>
          <div id="dish-form-buttons" className="form-row">
            <div className="form-group">
              <button className="btn" id="btn-mint" type="submit" name="submit">{ editMode ? "Update" : "Create"} Dish</button>
            </div>
            <div className="form-group">
              <button className="btn" id="btn-gray-right" type="submit" name="submit" onClick={ this.cancelClicked }>Cancel</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withCookies(DishForm);
