import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Link } from "react-router-dom";
import EventForm from "./EventForm";
import RecipeForm from "./RecipeForm";
import RecipeList from "./RecipeList";
import './Profile.css';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: this.props.cookies.get("pp-token"),
      currentUser: this.props.cookies.get("pp-user"),
      editRecipe: undefined,
      editEvent: undefined,
      eventSuccess: undefined,
      eventError: undefined,
      recipeSuccess: undefined,
      recipeError: undefined,
    };
  }

  componentDidMount(){
    if (!this.state.token) {
      this.props.history.push('/login');
    }
  }

  cancelEventUpdate = (event) => {
    this.setState({ editEvent: undefined })
  }

  cancelRecipeUpdate = (event) => {
    this.setState({ 
      editRecipe: undefined })
  }

  scrollToSection = (id) => {
    let element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  addEvent = (newEvent) => {
    this.props.addEventCallback(newEvent);
  }

  editEvent = (editedEvent) => {
    this.setState({ editEvent: undefined })
    this.props.editEventCallback(editedEvent);
  }

  editEventCallback = editEvent => event => {
    this.scrollToSection("event-section");
    this.setState({ editEvent: editEvent })
  }

  addRecipe = (newRecipe) => {
    this.props.addRecipeCallback(newRecipe);
  }

  editRecipe = (editedRecipe) => {
    this.setState({ editRecipe: undefined })
    this.props.editRecipeCallback(editedRecipe);
  }

  editRecipeCallback = editRecipe => event => {
    this.scrollToSection("recipe-section");
    this.setState({ editRecipe: editRecipe })
  }

  deleteEventCallback = deletedEvent => event => {
    if (window.confirm("Delete this event?")) {
      fetch(`${ process.env.REACT_APP_API_URL}/events/${ deletedEvent.external_id }`, {
        method: "DELETE", 
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${ this.state.token }`}
    }).then(response => {
      if (response.status === 204) {
        this.props.deleteEventCallback(deletedEvent);
      } else {
        this.setState({ 
          eventError: response.statusText
        })
      }
    })
    .catch(error => console.log(error))
    }
  }

  deleteRecipeCallback = deletedRecipe => event => {
    if (window.confirm("Delete this recipe?")) {
      fetch(`${ process.env.REACT_APP_API_URL }/recipes/${ deletedRecipe.id }`, {
        method: "DELETE", 
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${ this.state.token }`}
    }).then(response => {
      if (response.status === 204) {
        this.props.deleteRecipeCallback(deletedRecipe);
      } else {
        this.setState({ 
          recipeError: response.statusText
        })
      }
    })
    .catch(error => console.log(error))
    }
  }

  render() {
    let allEvents = this.props.events;

    let myEvents

    if (this.state.token){
      myEvents = allEvents.filter((event) => (event.host === this.state.currentUser.id));

      function compare(a, b) {
        if (a.title > b.title) return 1;
        if (b.title > a.title) return -1;
        return 0;
      }
    
      myEvents.sort(compare);

    } else {
      myEvents = []
    }

    let allRecipes = this.props.recipes;

    let myRecipes = allRecipes.filter((recipe) => (recipe.author === this.state.currentUser.id));

    return <div className="profile container-fluid">
      <h1>My Profile</h1>
      <h3>My Events</h3> 
      { this.state.eventSuccess ? <div className="alert alert-success" role="alert">{ this.state.eventSuccess }</div> : null }
      { this.state.eventError ? <div className="alert alert-warning" role="alert">{ this.state.eventError }</div> : null }
      <div className="my-events">
        <div className="event-list">
          { myEvents.map( event => { return <div className="card" key={ event.id }>
            <Link id="card-link" to={ "/events/" + event.external_id }>
              <img src={ event.photo_url ? event.photo_url : this.props.placeholder } className="card-img-top" alt={ event.title } />
            </Link>
            <div className="card-body">
              <p className="card-text"><Link id="card-link" to={ "/events/" + event.external_id }>{ event.title }</Link></p>
              <div className="card-buttons">
                <button type="submit" name="submit" className="btn" id="btn-mint-left" onClick={ this.editEventCallback(event) }>Edit</button>
                <button type="submit" name="submit" className="btn" id="btn-gray" onClick={ this.deleteEventCallback(event) }>Delete</button>
              </div>
            </div>
          </div>
          }
        )}
        </div>
      </div>
      <EventForm className="new-event-form" currentUser={ this.state.currentUser } token={ this.state.token } 
        addEventCallback={ this.addEvent } editEvent={ this.state.editEvent } editEventCallback={ this.editEvent }
        cancelClickedCallback={ this.cancelEventUpdate } />
      <h3>My Recipes</h3>
      { this.state.recipeSuccess ? <div className="alert alert-success" role="alert">{ this.state.recipeSuccess }</div> : null }
      { this.state.recipeError ? <div className="alert alert-warning" role="alert">{ this.state.recipeError }</div> : null }
      <div className="my-recipes">
        <RecipeList placeholder={ this.props.placeholder } recipes={ myRecipes } deleteRecipeCallback={ this.deleteRecipeCallback } 
          editRecipeCallback={ this.editRecipeCallback }/>
      </div>
      <RecipeForm className="new-recipe-form" currentUser={ this.state.currentUser } token={ this.state.token }
        addRecipeCallback={ this.addRecipe } editRecipe={ this.state.editRecipe } editRecipeCallback={ this.editRecipe } 
        cancelClickedCallback={ this.cancelRecipeUpdate } />
    </div>
  }
}

export default withCookies(Profile);
