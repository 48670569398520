import React, { Component } from "react";
import { withCookies } from "react-cookie";
import './SignUp.css';

class SignUpForm extends Component {
  state = {
    newUser: {
      username: "",
      password: "",
      confirm_password: "",
      email: "",
      first_name: "",
      last_name: "",
    },
    error: undefined
  }

  inputChanged = event => {
    let newUser = this.state.newUser;
    newUser[event.target.name] = event.target.value;
    this.setState({ newUser: newUser });
  }

  checkPasswords = () => {
    if (this.state.newUser.password !== this.state.newUser.confirm_password) {
      let updatedUser = this.state.newUser;
      updatedUser.password = "";
      updatedUser.confirm_password = "";

      this.setState({newUser: updatedUser});
      this.setState({error: "Your passwords don't match. Please try again."});
      return false
    } else {
      return true
    }
  }

  onFormSubmit = event => {
    event.preventDefault();

    if (this.checkPasswords() === true){
      fetch(`${process.env.REACT_APP_API_URL}/users/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.state.newUser)})
        .then( response => response.json())
        .then( response => { 
          if (response.id) {
            this.props.history.push('/login');
          } else if (response.username) {
            this.setState({ error: `Username: ${response.username[0]}`})
          } else if (response.email) {
            this.setState({ error: `Email: ${response.email[0]}`})
          }
        })
        .catch( error => {
          this.setState({ error: "The server is not available. Please try again later."})
        }
      )
    };
  }

  render(){
    return <div className="sign-up container-fluid">
      <h1>Sign Up</h1>
        { this.state.error ? <div className="alert alert-warning" role="alert">{this.state.error}</div> : null }
      <form className="sign-up-form" onSubmit={this.onFormSubmit} autoComplete="on">
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="username">Username</label>
            <input className="form-control" type="text" name="username" placeholder="Username" value={this.state.newUser.username}
              onChange={this.inputChanged} autoComplete="username" required />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="email">E-mail</label>
            <input className="form-control" type="email" name="email" placeholder="E-mail" value={this.state.newUser.email}
              onChange={this.inputChanged} autoComplete="email" required />
          </div>
        </div>
        <div className="form-row">  
          <div className="form-group col-md-3">
            <label htmlFor="password">Password</label>
            <input className="form-control" type="password" name="password" placeholder="Password" value={this.state.newUser.password}
              onChange={this.inputChanged} autoComplete="new-password" required />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="confirm_password">Confirm Password</label>
            <input className="form-control" type="password" name="confirm_password" placeholder="Confirm Password" value={this.state.newUser.confirm_password}
              onChange={this.inputChanged} autoComplete="new-password" required />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="first_name">First Name</label>
            <input className="form-control" type="text" name="first_name" placeholder="First Name" value={this.state.newUser.first_name}
              onChange={this.inputChanged} autoComplete="given-name" required />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="last_name">Last Name</label>
            <input className="form-control" type="text" name="last_name" placeholder="Last Name" value={this.state.newUser.last_name}
              onChange={this.inputChanged} autoComplete="family-name" required />
          </div>
        </div>
        <div className="form-row">
          <button type="submit" name="submit" className="btn" id="btn-mint">Create Account</button>
        </div>
      </form>
    </div>
  }
}

export default withCookies(SignUpForm);
