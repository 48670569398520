import React, { Component } from 'react';
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase/app";
import "firebase/storage";
import './RecipeForm.css';

class RecipeForm extends Component {
  constructor(props) {
    super(props);

    let userId = -1

    if (this.props.currentUser) {
      userId = this.props.currentUser.id
    } 

    this.state = {
      currentRecipe: {
        title: "",
        author: userId,
        servings: "",
        prep_time: "",
        cook_time: "",
        photo_url: "",
        ingredients: "",
        directions: ""
      },
      editMode: !!props.editRecipe,
      uploadName: "No file chosen",
      success: undefined,
      error: undefined,
      key: 0
    };
  }

  componentDidMount(){
    if (this.userId === -1) {
      this.props.history.push('/login');
    } 
  }

  componentDidUpdate(prevProps){
    if (this.props.editRecipe && (this.props.editRecipe !== prevProps.editRecipe)) {
      let recipeCopy = JSON.parse(JSON.stringify(this.props.editRecipe));

      this.setState({
        currentRecipe: recipeCopy,
        editMode: !!this.props.editRecipe,
        uploadName: "No file chosen",
        success: undefined,
        error: undefined
      })
    }
  }

  setPhoto(filename, url) {
    let updatedRecipe = this.state.currentRecipe;
    updatedRecipe.photo_url = url;

    this.setState({ 
      currentRecipe: updatedRecipe,
      uploadName: filename })    
  }

  handleUploadSuccess = filename => {
    firebase
      .storage()
      .ref("recipes")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setPhoto(filename, url))
  };

  handleUploadError = error => {
    console.log(error);
  }

  cancelClicked = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;
    
    this.setState({ 
      currentRecipe: { 
        title: "", author: this.props.currentUser.id, servings: "", prep_time: "", cook_time: "", photo_url: "", ingredients: "", directions: "" 
      },
      editMode: false,
      uploadName: "No file chosen",
      success: undefined,
      error: undefined,
      key: newKey,
    })

    this.props.cancelClickedCallback();
  }

  inputChanged = event => {
    let updatedRecipe = this.state.currentRecipe;
    updatedRecipe[event.target.name] = event.target.value;
    this.setState({ currentRecipe: updatedRecipe });
  }
  
  createRecipe = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;

    this.setState({ 
      key: newKey
    })

    fetch(`${process.env.REACT_APP_API_URL}/recipes/`, {
      method: "POST", 
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${ this.props.token }`},
      body: JSON.stringify(this.state.currentRecipe)})
      .then(response => response.json())
      .then(response => {
        if (response.id) {
          let recipeTitle = response.title;
          this.props.addRecipeCallback(response);
          this.setState({ 
            currentRecipe: { 
              title: "", author: this.props.currentUser.id, servings: "", prep_time: "", cook_time: "", photo_url: "", ingredients: "", directions: "" 
            },
            uploadName: "No file chosen",
            success: `Recipe ${ recipeTitle } was successfully added.`,
            error: undefined
          })
        } else {
          this.setState({ error: response.detail })
        }
      })
      .catch(error => console.log(error)
      )
    }

  editRecipe = event => {
    event.preventDefault();

    let newKey = this.state.key + 1;

    this.setState({ 
      key: newKey
    })

    fetch(`${ process.env.REACT_APP_API_URL }/recipes/${ this.state.currentRecipe.id }/`, {
      method: "PATCH", 
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${ this.props.token }`},
      body: JSON.stringify(this.state.currentRecipe)})
      .then(response => response.json())
      .then(response => {
        if (response.id) {
          let recipeTitle = response.title;
          this.props.editRecipeCallback(response);
          this.setState({
            currentRecipe: {
              title: "", author: this.props.currentUser.id, servings: "", prep_time: "", cook_time: "", photo_url: "", ingredients: "", directions: ""
            },
            editMode: false,
            uploadName: "No file chosen",
            success: `Recipe ${recipeTitle} was successfully updated.`,
            error: undefined
          })
        } else {
          this.setState({ error: response.detail })
        }
      })
      .catch(error => console.log(error)
      )
    }

  render() {
    let selectedRecipe = this.state.currentRecipe;

    let editMode = this.state.editMode;

    return (
      <div id="recipe-section" className="recipe-form"> 
        <h3>{ editMode ? "Edit" : "Add" } Recipe</h3>
        { this.state.success ? <div className="alert alert-success" role="alert">{ this.state.success }</div> : null }
        { this.state.error ? <div className="alert alert-warning" role="alert">{ this.state.error }</div> : null }
        <form className="recipe-form" onSubmit={ editMode ? this.editRecipe : this.createRecipe } autoComplete="on">
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="title">Title</label>
              <input className="form-control" type="text" name="title" placeholder="Title" value={ selectedRecipe.title } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="servings">Servings</label>
              <input className="form-control" type="number" name="servings" placeholder="Servings" value={ selectedRecipe.servings } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="prep_time">Prep Time</label>
              <input className="form-control" type="text" name="prep_time" placeholder="Prep Time" value={ selectedRecipe.prep_time } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="cook_time">Cook Time</label>
              <input className="form-control" type="text" name="cook_time" placeholder="Cook Time" value={ selectedRecipe.cook_time } 
                onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-3">
              <div><label>Photo</label></div>
              <label id="recipe-photo-upload" className="btn">
                Choose
                <FileUploader key={ this.state.key } hidden accept="image/*" name="photo" storageRef={ firebase.storage().ref("recipes") } 
                  onUploadSuccess={ this.handleUploadSuccess } onUploadError={ this.handleUploadError } /> 
              </label>
              <span id="filename">{ this.state.uploadName }</span>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-5">
              <label>Ingredients</label>
              <textarea className="form-control" rows="10" name="ingredients" placeholder="Ingredients"
                value={ selectedRecipe.ingredients } onChange={ this.inputChanged } required />
            </div>
            <div className="form-group col-md-7">
              <label>Directions</label>
              <textarea className="form-control" rows="10" name="directions" placeholder="Directions"
                value={ selectedRecipe.directions } onChange={ this.inputChanged } required />
            </div>
          </div>
          <div id="recipe-form-buttons" className="form-row">
            <div className="form-group">  
              <button className="btn" id="btn-new-recipe" type="submit" name="submit">{ editMode ? "Update" : "Create" } Recipe</button>
            </div>
            <div className="form-group">
              <button className="btn btn-gray-right" type="submit" name="submit" onClick={ this.cancelClicked }>Cancel</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RecipeForm;
