import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search_terms: "",
      external_results: undefined,
    };
  }

  inputChanged = event => {
    let updatedSearchTerms = this.state.search_terms;
    updatedSearchTerms = event.target.value;
    this.setState({ search_terms: updatedSearchTerms });
  }

  getResults = () => {
    let baseUrl = `${process.env.REACT_APP_RECIPES_URL}?app_id=${process.env.REACT_APP_RECIPES_ID}&app_key=${process.env.REACT_APP_RECIPES_KEY}&type=public`

    let queryUrl = baseUrl + `&q=${this.state.search_terms}`;

    fetch(queryUrl, {
      method: "GET",
      })
      .then( response => response.json())
      .then( response => { 
        this.setState({ external_results: response.hits })    
      })
      .then(response => {
        this.props.searchCallback(this.props, this.state.search_terms, this.state.external_results);
      })
      .catch( error => { this.setState({ error: error })})
  }

  onFormSubmit = event => {
    event.preventDefault();
    this.getResults();
  }

  render(){
    return (<form className="form-inline my-2 my-lg-0" onSubmit={ this.onFormSubmit } >
      <input id="nav-search" className="form-control mr-sm-2" type="text" placeholder="Recipe Search" aria-label="Search" onChange={ this.inputChanged } />
      <button className="btn my-2 my-sm-0" id="btn-gray" type="submit">Search</button>
    </form>
    );
  }
}

export default withRouter(SearchBar);
