import React from "react";
import { withCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { Email, Facebook, Pinterest, Twitter } from "react-sharingbuttons";
import 'react-sharingbuttons/dist/main.css';
import PropTypes from 'prop-types';
import './Recipe.css';

const Recipe = (props) => {
  let { recipeId } = props.match.params;

  let recipes = props.recipes;

  let foundRecipe = recipes.find((recipe) => recipe.id === parseInt(recipeId));

  const addSelectedCallback = selectedRecipe => event => {
    let dishDetails = { name: selectedRecipe.title, link: `/recipes/${ selectedRecipe.id }`, photo_url: selectedRecipe.photo_url}
    props.addSelectedCallback(dishDetails)
  }

  const sharingButtons = (id, title, photo ) => {
    const url = `https://potluckpal.com/recipes/${ id }`
    const text = `Check out this recipe for ${ title }!`

    return (
      <div id="recipe-share">
        <div>
          <Email subject={ text } url={ url }/>
          <Facebook url={ url } />
        </div>
        <div>
          <Pinterest shareText={ text } url={ url } mediaSrc={ photo } />
          <Twitter shareText={ text } url={ url } />
        </div>
      </div>
    )
  }

  if (foundRecipe) {
    let user = props.users.find((user) => user.id === foundRecipe.author);

    return (
      <div className="recipe container-fluid">
        <h1>{ foundRecipe.title }
          <button type="submit" name="submit" className="btn" id="select-recipe" 
            onClick={ addSelectedCallback(foundRecipe) }>Select Recipe</button>
        </h1>
        <div className="recipe-header">
          <img src={ foundRecipe.photo_url ? foundRecipe.photo_url : props.placeholder } alt={ foundRecipe.title } />
          <div className="recipe-details">
            <p><span className="label">Author: </span>{ user.username }</p>
            <p><span className="label">Servings: </span>{ foundRecipe.servings }</p>
            <p><span className="label">Prep Time: </span>{ foundRecipe.prep_time }</p>
            <p><span className="label">Cook Time: </span>{ foundRecipe.cook_time }</p>
          </div>
          <div>
            { sharingButtons(foundRecipe.id, foundRecipe.title, foundRecipe.photo_url) }
          </div>
        </div>  
        <div className="recipe-ingredients">
          <h3>Ingredients</h3>
          { foundRecipe.ingredients }
        </div>
        <div className="recipe-directions">
          <h3>Directions</h3>
          { foundRecipe.directions }
        </div>
      </div>    
    );
  } else {
    return (
      <div className="recipe container-fluid">
        <div className="alert alert-warning" role="alert">No recipe found</div>
          <Link to="/recipes">
            <div className="missing-recipe">
              <button type="button" className="btn" id="btn-mint">
                Back to All Recipes
              </button>
            </div>
          </Link>
      </div>
    )
  }
}

Recipe.propTypes = {
  recipes: PropTypes.array.isRequired,
  users: PropTypes.array,
}

export default withCookies(Recipe);
