import React from 'react';
import Dish from './Dish';

const DishList = (props) => {  

  const deleteClicked = dish => event => {
    if (window.confirm("Delete this dish?")) {
      fetch(`${process.env.REACT_APP_API_URL}/dishes/${dish.id}`, {
        method: "DELETE", 
        headers: {
          "Content-Type": "application/json",
        },
    }).then(response => props.deleteDishCallback(dish))
    .catch(error => console.log(error))
    }
  }

  const editClicked = editDish => event => {
    props.editDishCallback(editDish)
  }

  let sortedDishes = props.dishes

  function compare(a, b) {
    if (a.id > b.id) return 1;
    if (b.id > a.id) return -1;
    return 0;
  }

  sortedDishes.sort(compare);

  const dishCollection = sortedDishes.map((dish, i) => {
    return (
      <tr key={ i }>
        <td>{ i + 1 }</td>
        <Dish id={ dish.id } event={ dish.event } provider= { dish.provider } photo={ dish.photo_url ? dish.photo_url : props.placeholder } 
          name={ dish.name } link={ dish.link } notes={ dish.notes } editDishCallback={ editClicked } deleteDishCallback={ deleteClicked } />
      </tr>
    );
  });

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Guest</th>
          <th scope="col">Dish</th>
          <th scope="col">Image</th>
          <th scope="col">Notes, Food Allergens</th>
          <th scope="col">Edit</th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      <tbody>
        { dishCollection }
      </tbody>
    </table>
  );
};

export default DishList;
