import firebase from "firebase/app";
import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import { withCookies } from "react-cookie";
import { Switch, Route, Link } from "react-router-dom";
import Event from "./components/Event";
import Profile from "./components/Profile";
import Home from "./components/Home";
import LogIn from "./components/LogIn";
import Recipe from "./components/Recipe";
import RecipeList from "./components/RecipeList";
import Search from "./components/Search";
import SignUp from "./components/SignUp";
import SearchBar from "./components/SearchBar";
import config from "./components/firebase-config";
import './App.css';

firebase.initializeApp(config);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      events: [],
      recipes: [],
      dishes: [],
      search_terms: "",
      external_results: undefined,
      selectedRecipe: undefined,
      error: undefined
    };
  }

  placeholder = "https://res.cloudinary.com/higrf51e4/image/upload/v1579480979/placeholder.jpg"

  componentDidMount(){
    fetch(`${process.env.REACT_APP_API_URL}/users/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${process.env.REACT_APP_TOKEN}`}
    })
      .then( response => response.json())
      .then( response => { this.setState({ users: response })})
      .catch( error => { this.setState({ error: error })})
    
    fetch(`${process.env.REACT_APP_API_URL}/events/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${process.env.REACT_APP_TOKEN}`}
    })
      .then( response => response.json())
      .then( response => { this.setState({ events: response })})
      .catch( error => { this.setState({ error: error })})

    fetch(`${process.env.REACT_APP_API_URL}/recipes/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${process.env.REACT_APP_TOKEN}`}
    })
      .then( response => response.json())
      .then( response => { this.setState({ recipes: response })})
      .catch( error => { this.setState({ error: error })})
    
    fetch(`${process.env.REACT_APP_API_URL}/dishes/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${process.env.REACT_APP_TOKEN}`}
    })
      .then( response => response.json())
      .then( response => { this.setState({ dishes: response })})
      .catch( error => { this.setState({ error: error })})
    }

  logOut() {
    this.props.cookies.remove('pp-token', { path: "/"});
    this.props.cookies.remove('pp-user', {path: "/"});
  }

  addEvent = (newEvent) => {
    let updatedEvents = this.state.events;
    updatedEvents.push(newEvent);
    this.setState({ events: updatedEvents })
  }

  addRecipe = (newRecipe) => {
    let updatedRecipes = this.state.recipes;
    updatedRecipes.push(newRecipe);
    this.setState({ recipes: updatedRecipes })
  }

  addDish = (newDish) => {
    let updatedEvents = this.state.events;
    let selectedEventIndex = this.state.events.findIndex((event) => event.id === newDish.event);
    
    updatedEvents[selectedEventIndex].dishes.push(newDish)

    let updatedDishes = this.state.dishes;
    updatedDishes.push(newDish);

    this.setState({ 
      events: updatedEvents,
      dishes: updatedDishes 
    })
  }

  editEvent = (editedEvent) => {
    let updatedEvents = this.state.events;
    let selectedEventIndex = updatedEvents.findIndex((event) => event.id === editedEvent.id);
    
    updatedEvents[selectedEventIndex] = editedEvent;

    this.setState({ events: updatedEvents })
  }

  editRecipe = (editedRecipe) => {
    let updatedRecipes = this.state.recipes;
    let selectedRecipeIndex = updatedRecipes.findIndex((recipe) => recipe.id === editedRecipe.id);
    
    updatedRecipes[selectedRecipeIndex] = editedRecipe;
    
    this.setState({ recipes: updatedRecipes })
  }

  editDish = (editedDish) => {
    let updatedEvents = this.state.events;
    let selectedEventIndex = this.state.events.findIndex((event) => event.id === editedDish.event);
    let selectedDishIndex = this.state.events[selectedEventIndex].dishes.findIndex((dish) => dish.id === editedDish.id);

    updatedEvents[selectedEventIndex].dishes[selectedDishIndex] = editedDish

    let updatedDishes = this.state.dishes;
    let dishIndex = updatedDishes.findIndex((dish) => dish.id === editedDish.id);

    updatedDishes[dishIndex] = editedDish;

    this.setState({ 
      events: updatedEvents,
      dishes: updatedDishes
    })
  }

  deleteEvent = (deletedEvent) => {
    let updatedEvents = this.state.events.filter((event) => event.id !== deletedEvent.id);

    this.setState({ events: updatedEvents })    
  }

  deleteRecipe = (deletedRecipe) => {
    let updatedRecipes = this.state.recipes.filter((recipe) => recipe.id !== deletedRecipe.id);

    this.setState({ recipes: updatedRecipes })    
  }

  deleteDish = (deletedDish) => {
    let updatedEvents = this.state.events;
    let selectedEvent = updatedEvents.find((event) => event.id === deletedDish.event);

    let updatedDishes = selectedEvent.dishes.filter((dish) => dish.id !== deletedDish.id)
    selectedEvent.dishes = updatedDishes;

    this.setState({ events: updatedEvents })    
  }

  addSelected = (dishDetails) => {
    this.setState({ selectedRecipe: dishDetails })
  }

  clearSelected = () => {
    this.setState({ selectedRecipe: undefined })
  }

  inputChanged = event => {
    let updatedSearchTerms = this.state.search_terms;
    updatedSearchTerms = event.target.value;
    this.setState({ search_terms: updatedSearchTerms });
  }

  onFormSubmit = (props, search, results) => {
    this.setState({ search_terms: search });
    this.setState({external_results: results})
    props.history.push('/search');
  }

  render() {
    let currentUser = this.props.cookies.get("pp-user", { path: "/" });
    let crockpot = "https://res.cloudinary.com/higrf51e4/image/upload/v1579234490/potluckpal.png"

    return (
      <div>
        <Navbar expand="lg">
          <Link className="navbar-brand" to="/">
            <img src={ crockpot } alt="crockpot" className="d-inline-block align-top" id="nav-crockpot"/>
          </Link>
          <Link className="navbar-brand" id="logo" to="/">Potluck Pal</Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                { currentUser ? <Link className="nav-link" id="link" onClick={ this.logOut.bind(this) } to="/">Log Out</Link>
                  : <Link className="nav-link" id="link" to="/login">Log In</Link>}
              </li>
              <li className="nav-item">
                { currentUser ? <Link className="nav-link" id="link" to="/profile">My Profile</Link>
                  : <Link className="nav-link" id="link" to="/signup">Sign Up</Link> } 
              </li>
              <li className="nav-item">
                <Link className="nav-link" id="link" to="/recipes">All Recipes</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" id="link" to="/search">Recipe Search</Link>
              </li>
              <li id="selected-recipe-section">
                <div id="selected-label">
                  <div>Selected Recipe</div>
                  <div>{ this.state.selectedRecipe ? this.state.selectedRecipe.name : "None" }</div>
                </div>
              </li>
              <div className="nav-link">
                <button className="btn my-2 my-sm-0" id="clear-button" type="submit" onClick={ this.clearSelected }>Clear</button>
              </div>
            </ul>
            <SearchBar searchCallback={ this.onFormSubmit }/>
          </Navbar.Collapse>
        </Navbar>
        { this.state.error ? <div className="alert alert-warning" role="alert">{this.state.error}</div> : null }
        <Switch>
          <Route exact path="/login" component={ LogIn } />
          <Route exact path="/signup" component={ SignUp } />
          <Route exact path="/profile" render={ (props) => 
            <Profile { ...props } users={ this.state.users } events={ this.state.events } recipes={ this.state.recipes } 
              placeholder={ this.placeholder } addEventCallback={ this.addEvent} addRecipeCallback={ this.addRecipe } 
              editEventCallback={ this.editEvent } editRecipeCallback={ this.editRecipe } 
              deleteEventCallback={ this.deleteEvent } deleteRecipeCallback={ this.deleteRecipe } />
          }/>
          <Route exact path="/events/:eventId" render={(props) => 
            <Event { ...props } placeholder={ this.placeholder } users={ this.state.users } events={ this.state.events } dishes={ this.state.dishes }
            selectedRecipe={ this.state.selectedRecipe } addDishCallback={ this.addDish } editDishCallback={ this.editDish } deleteDishCallback={ this.deleteDish }/>
          }/>
          <Route exact path="/recipes" render={(props) => 
            <React.Fragment>
              <div className="recipe container-fluid">
              <h1>All Recipes</h1>
              <RecipeList { ...props } addSelectedCallback={ this.addSelected } placeholder={ this.placeholder } users={ this.state.users } recipes={ this.state.recipes } />
              </div>
            </React.Fragment>
          }/>
          <Route exact path="/recipes/:recipeId" render={(props) => 
            <Recipe { ...props } placeholder={ this.placeholder } addSelectedCallback={ this.addSelected }
              users={ this.state.users } recipes={ this.state.recipes } />
          }/>
          <Route exact path="/search" render={(props) => 
            <Search { ...props } placeholder={ this.placeholder } search_terms={ this.state.search_terms } external_results={ this.state.external_results } 
              addSelectedCallback={ this.addSelected } users={ this.state.users } recipes={ this.state.recipes } />
          }/>
          <Route path="/" component={ Home } />
        </Switch>
      </div>
    );
  }
}

export default withCookies(App);
