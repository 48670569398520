import React from "react";
import { withCookies } from "react-cookie";
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import './Home.css';

function Home(props) {
  let currentUser = props.cookies.get("pp-user", { path: "/" });

  return <div className="home container-fluid">
    <h1>Welcome to Potluck Pal!</h1>
    <Carousel>
      <Carousel.Item>
        <Link to="/signup">
          <img
            className="d-block w-100"
            src="https://res.cloudinary.com/higrf51e4/image/upload/v1579483627/account.jpg"
            alt="First slide"
          />
          </Link>
        <Carousel.Caption>
          <Link to="/signup">
            <h3>Create an Account</h3>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Link to={ currentUser ? "/profile" : "/signup"}>
          <img
            className="d-block w-100"
            src="https://res.cloudinary.com/higrf51e4/image/upload/v1579483637/potluck.jpg"
            alt="Second slide"
          />
        </Link>
        <Carousel.Caption>
          <Link className="nav-link" id="link" to={ currentUser ? "/profile" : "/signup" }>
            <h3>
              Organize a Potluck
            </h3>
            </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/search">
          <img
            className="d-block w-100"
            src="https://res.cloudinary.com/higrf51e4/image/upload/v1579483650/pasta.jpg"
            alt="Third slide"
          />
        </Link>
        <Carousel.Caption>
          <Link to="/search">
            <h3>Search for Recipes</h3>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  </div>
}

export default withCookies(Home);
