import React, { Component } from "react";
import { withCookies } from "react-cookie";
import './LogIn.css';

class LogIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      credentials: {
        username: "",
        password: ""
      },
      error: undefined
    }
  }

  inputChanged = event => {
    let credentials = this.state.credentials;
    credentials[event.target.name] = event.target.value;
    this.setState({ credentials: credentials });
  }

  onFormSubmit = event => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_API_URL}/auth/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.credentials)})
      .then( response => response.json())
      .then( response => { 
        if (response.token) {
          this.props.cookies.set("pp-token", response.token, { path: "/" });
          this.props.cookies.set("pp-user", response.user, { path: "/" });
          this.setState({ error: undefined });
          this.props.history.push('/profile');
        } else if (response.non_field_errors) {
          this.setState({ error: response.non_field_errors[0], credentials: { username: "", password: "" }})
        }
      })
      .catch( error => {
        this.setState({ error: "The server is not available. Please try again later." })
      }
    )
  }

  render(){
    return <div className="log-in container-fluid">
      <h1>Log In</h1>
        { this.state.error ? <div className="alert alert-warning" role="alert">{this.state.error}</div> : null }
      <form className="log-in-form" onSubmit={this.onFormSubmit} autoComplete="on">
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="username">Username</label>
            <input className="form-control" type="text" name="username" placeholder="Username" value={this.state.credentials.username}
              onChange={this.inputChanged} autoComplete="username" required />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="password">Password</label>
            <input className="form-control" type="password" name="password" placeholder="Password" value={this.state.credentials.password}
              onChange={this.inputChanged} autoComplete="current-password" required />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <button type="submit" name="submit" className="btn" id="btn-mint">Log In</button>
          </div>
        </div>
      </form>
    </div>
  }
}

export default withCookies(LogIn);
